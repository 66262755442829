import {
    Autocomplete,
    DialogContent,
    DialogContentText,
    TextField,
} from "@mui/material";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import { ApiService } from "common/services/ApiService";
import React, { useEffect, useState } from "react";
import { ROLE_MAP } from "utils/constants";

const DialogUserEstablishmentContent = ({
    selectedUser,
    isSuperAdmin,
    handleChangeExternalData,
    defaultEstablishment = {},
    showPreviousRoleList = true
}) => {
    const [optionsRole, setOptionsRole] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [establishment, setEstablishment] = useState(undefined);

    const getRoles = async () => {
        const resultRoles = await ApiService.getRoles();
        const roleNameList = resultRoles.data.map((r) => ({
            id: r.id,
            value: r.name,
            label: ROLE_MAP[r.name],
        }));
        setOptionsRole(roleNameList);
        return roleNameList;
    };

    const getUserRoleEstablishments = async (newEstablishment) => {
        const result = await ApiService.getUserRoleEstablishments({
            user: selectedUser.id,
            establishment: newEstablishment?.id,
        });
        const roleList = await getRoles();
        const roleNameList = result.data.map((ure) => ure.role);
        const roleUserList = roleList.filter((r) => roleNameList.includes(r?.value));

        if (showPreviousRoleList) {
            setSelectedRoles(roleUserList);
            setOptionsRole(roleList);
            return
        }

        const rolesNotInUser = roleList.filter((r) => !roleNameList.includes(r?.value));
        setOptionsRole(rolesNotInUser);

    };

    useEffect(() => {
        if (!isSuperAdmin) {
            getUserRoleEstablishments(defaultEstablishment);
            setEstablishment(defaultEstablishment);
            handleChangeExternalData({
                establishment: defaultEstablishment?.id,
            });
        }
    }, [isSuperAdmin]);

    return (
        <DialogContent>
            <DialogContentText>
                <strong>Nombre:</strong> {selectedUser.first_name} {selectedUser.last_name}
            </DialogContentText>
            <SelectProvinceAndLocation
                handleChangeEstablishment={(newEstablishment) => {
                    setEstablishment(newEstablishment);
                    handleChangeExternalData({
                        establishment: newEstablishment?.id,
                    });
                    setSelectedRoles([]);
                    setOptionsRole([]);
                    if (newEstablishment) {
                        getUserRoleEstablishments(newEstablishment);
                    }
                }}
                showEstablishments
                gridSizes={{
                    province: { xs: 12, lg: 12, sm: 12 },
                    location: { xs: 12, lg: 12, sm: 12 },
                    establishment: { xs: 12, lg: 12, sm: 12 },
                }}
            ></SelectProvinceAndLocation>
            <Autocomplete
                disabled={establishment === undefined}
                sx={{ marginTop: "16px" }}
                id="autocomplete"
                // freeSolo
                multiple
                options={optionsRole}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.label}
                        </li>
                    );
                }}
                getOptionLabel={(option) => {
                    return option.label;
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Asignar roles para la institución" />
                )}
                value={selectedRoles}
                onChange={(evt, newValue) => {
                    if (newValue !== null) {
                        setSelectedRoles(newValue);
                        handleChangeExternalData({ roles: newValue });
                    }
                }}
            />
        </DialogContent>
    );
};

export default DialogUserEstablishmentContent;
