import React, { useState } from "react";

import DrawerComponent from "common/components/DrawerComponent/DrawerComponent";

import CardMembershipIcon from "@mui/icons-material/CardMembership";
import GroupIcon from "@mui/icons-material/Group";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import {
    Badge,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import UsersList from "modules/authentication/components/UsersList/UsersList";
import EstablishmentsList from "modules/authentication/components/EstablishmentsList/EstablishmentsList";
import DatasetsList from "modules/authentication/components/DatasestsList/DatasetsList";
import { ApiService } from "common/services/ApiService";
import CoursesList from "modules/authentication/components/CoursesList/CorusesList";
import { useAuth } from "context/AuthContext";
import Datasets from "modules/activity/pages/Datasets/Datasets";
import SubjectsList from "modules/authentication/components/SubjectsList/SubjectsList";

const Admin = ({ handleDrawerToggle, openDrawer }) => {
    const { getEstablishments, getFilterUsers } = ApiService;
    const { auth } = useAuth();

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [dataToShow, setDataToShow] = useState(<h1>Menú de Administrador</h1>);

    const firstList = [
        {
            id: 1,
            title: "Cursos",
            component: (
                <CoursesList
                    isAdmin={true}
                    columnVisibilityModel={{
                        actions: false,
                    }}
                />
            ),
            icon: <CardMembershipIcon></CardMembershipIcon>,
            badgeKey: "course",
            selected: false,
        },
        {
            id: 2,
            title: "Materias",
            component: <SubjectsList isAdmin={true} />,
            icon: <AutoStoriesIcon></AutoStoriesIcon>,
            selected: false,
        },
        {
            id: 3,
            title: "Usuarios",
            component: (
                <UsersList
                    getUsersFunction={ApiService.getFilterUsers}
                    isSuperAdmin={false}
                    // columnVisibilityModel={{
                    //     role: false,
                    //     establishments: false,
                    // }}
                ></UsersList>
            ),
            icon: <GroupIcon></GroupIcon>,
            badgeKey: "user",
            selected: false,
        },
        {
            id: 4,
            title: "Datasets",
            component: <Datasets />,
            icon: <InsertDriveFileIcon></InsertDriveFileIcon>,
            selected: false,
        },
    ];

    const drawer = (
        <div>
            <List>
                {firstList.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setDataToShow(item.component);
                                handleDrawerToggle();
                                setSelectedItemId(item.id);
                            }}
                            selected={selectedItemId === item.id}
                        >
                            <ListItemIcon>
                                {Boolean(item.badgeKey) &&
                                Object.keys(auth.pendingNotification || {}).length > 0 ? (
                                    <Badge
                                        variant="dot"
                                        color="secondary"
                                        invisible={
                                            !auth.pendingNotification["admin"][item.badgeKey]
                                        }
                                    >
                                        {item.icon}
                                    </Badge>
                                ) : (
                                    item.icon
                                )}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </div>
    );

    return (
        <DrawerComponent
            drawer={drawer}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={openDrawer}
        >
            {dataToShow}
        </DrawerComponent>
    );
};

export default Admin;
