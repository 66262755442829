import React, { useEffect, useState } from "react";

import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    DialogContent,
    Grid,
    TextField,
} from "@mui/material";
import { ApiService } from "common/services/ApiService";
import FilterBar from "common/components/FilterBar/FilterBar";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import { useAuth } from "context/AuthContext";
import DialogContentInputs from "./DialogContentInputs";
import { useNavigate } from "react-router-dom";
import GenericAlert from "common/components/GenericAlert/GenericAlert";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";

const CoursesList = ({
    isSuperAdmin = false,
    isAdmin = false,
    columnVisibilityModel,
}) => {
    const navigate = useNavigate();

    const { auth } = useAuth();
    const [courses, setCourses] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});

    const [userRequests, setUserRequests] = useState([]);

    const [openCourseDialog, setOpenCourseDialog] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [showAlertOk, setShowAlertOk] = useState(false);
    const [showAlertFail, setShowAlertFail] = useState(false);

    const [openGenericDialog, setOpenGenericDialog] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [handleSaveFunction, setHandleSaveFunction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContentText, setDialogContentText] = useState("");
    const [actualEstablishment, setActualEstablishment] = useState(
        auth.selectedRole?.name === "superadmin"
            ? {}
            : auth?.selectedEstablishment
    );

    const [extraFilterComponent, setExtraFilterComponent] = useState({
        establishment:
            auth.selectedRole?.name === "superadmin"
                ? ""
                : auth?.selectedEstablishment?.id,
    });

    const [courseFormData, setCourseFormData] = useState({
        name: "",
        subject: "",
        start_date: "",
        end_date: "",
        cicle: "",
        year: "",
        establishment: auth?.selectedEstablishment?.id,
    });

    const getUserCourseRequests = async () => {
        const dataUser = await ApiService.me(auth.access);
        const res = await ApiService.getUserCourseRequests(null, null, {
            no_page: 1,
            user: dataUser.data.id,
        });

        setUserRequests(res.data);
    };
    useEffect(() => {
        getUserCourseRequests();
    }, []);

    const getCourses = async (filter = "", extraFilter = {}, page = 1, pageSize = 10) => {
        setOpenLoader(true);
        const res = await ApiService.getCourses(page, pageSize, {
            ...extraFilter,
            name: filter,
        });
        setRowCount(res.data.count);
        setPageSize(pageSize);
        setPage(page);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setCourses(
            res.data.results.map((course) => ({
                id: course.id,
                name: course.name,
                establishment: course.establishment.name,
                subject: course.subject,
                startDate: course.start_date,
                endDate: course.end_date,
                cicle: course.cicle,
                year: course.year,
            }))
        );
        setOpenLoader(false);
    };

    const handleJoinCourse = async (id) => {
        const dataUser = await ApiService.me(auth.access);

        const data = {
            course: id,
            user: dataUser.data.id,
            role: auth.selectedRole.name,
        };
        const res = await ApiService.joinCourse(data);

        if (res.status === 200) {
            getUserCourseRequests();
            setShowAlertOk(true);
            setTimeout(() => {
                setShowAlertOk(false);
            }, 2500);
        } else {
            setShowAlertFail(true);
            setTimeout(() => {
                setShowAlertFail(false);
            }, 2500);
        }
        setOpenGenericDialog(false);
    };

    const handleClickJoinCourseButton = (id) => {
        setDialogTitle("Está seguro que desea unirse a este curso?");
        setDialogContentText(
            "Se solicitará la inscripción al curso y deberá esperar que un administrador acepte su solicitud"
        );
        setSelectedCourseId(id);
        setHandleSaveFunction(() => handleJoinCourse);
        setOpenGenericDialog(true);
    };

    const handleExitCourse = async (id) => {
        setDialogTitle("Está seguro que desea abandonar este curso?");
        setDialogContentText(
            "Perderá el acceso al curso y a todo su contenido"
        );
        setSelectedCourseId(id);
        // HAY QUE HACER FUNCIONALIDAD PARA SALIR DEL CURSO
        // setHandleSaveFunction(() => handleQuitCourse);
        setOpenGenericDialog(true);
    };
    const handleShowActivitiesFromCourse = async (id) => {
        navigate(`/${auth.selectedRole?.name}/course/${id}`);
    };

    const adminTableCoursesColumns = [
        {
            field: "name",
            headerName: "Nombre",
            width: 150,
        },
        {
            field: "subject",
            headerName: "Materia",
            width: 150,
            renderCell: (params) => params.row.subject?.name,
        },
        {
            field: "startDate",
            headerName: "Fecha de inicio",
            width: 150,
            renderCell: (params) => params.row.startDate.split("-").reverse().join("/"),
        },
        {
            field: "endDate",
            headerName: "Fecha de fin",
            width: 150,
            renderCell: (params) => params.row.endDate.split("-").reverse().join("/"),
        },
        {
            field: "cicle",
            headerName: "Ciclo lectivo",
            width: 150,
        },
        {
            field: "year",
            headerName: "Año de cursada",
            width: 150,
            renderCell: (params) => `${params.row.year}°`,
        },
        {
            field: "establishment",
            headerName: "Establecimiento",
            width: 150,
        },
        {
            field: "actions",
            headerName: "Acciones",
            // flex: 1,
            width: 350,
            hideable: true,
            renderCell: (params) => {
                const exists = userRequests.find((req) => req.course.id === params.row.id);
                if (exists !== undefined) {
                    return (
                        <Box display={"flex"} gap={1}>
                            <Button
                                variant="contained"
                                disabled={exists?.pending === true}
                                onClick={() => {
                                    handleShowActivitiesFromCourse(params.row.id);
                                }}
                                // sx={{ backgroundColor: "#db0909" }}
                            >
                                Ver curso
                            </Button>
                            <Button
                                variant="contained"
                                disabled={exists?.pending === true}
                                onClick={() => {
                                    handleExitCourse(params.row.id);
                                }}
                                color="error"
                            >
                                {exists?.pending === true ? "Pendiente" : "Abandonar"}
                            </Button>
                        </Box>
                    );
                } else {
                    return (
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClickJoinCourseButton(params.row.id);
                                // handleJoinCourse(params.row.id);
                            }}
                        >
                            Unirse
                        </Button>
                    );
                }
            },
        },
        {
            field: "adminActions",
            headerName: "Acciones",
            width: 350,

            hideable: true,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleShowActivitiesFromCourse(params.row.id);
                        }}
                        // sx={{ backgroundColor: "#db0909" }}
                    >
                        Ver curso
                    </Button>
                );
            },
        },
    ];

    const handleCreateCourse = async (evt) => {
        try {
            setOpenCourseDialog(false);
            setOpenLoader(true);
            await ApiService.createCourse(courseFormData);
            setOpenLoader(false);
            getCourses(filter, extraFilter, page, pageSize);
            setAlertText("El curso se creó con éxito!");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 2500);
        } catch (error) {
            console.log(error);
        }
    };

    const componentFilterBarSuperAdmin = (
        <div>
            <SelectProvinceAndLocation
                handleChangeProvince={() => {}}
                handleChangeLocation={() => {}}
                handleChangeEstablishment={(establishment) => {
                    setExtraFilterComponent({
                        ...extraFilterComponent,
                        establishment: establishment?.id,
                    });
                    setActualEstablishment(establishment);
                    setCourseFormData({
                        ...courseFormData,
                        establishment: establishment?.id,
                    });
                }}
                showEstablishments
                xs={12}
                gridSizes={{
                    province: { xs: 12, lg: 4, sm: 4 },
                    location: { xs: 12, lg: 4, sm: 4 },
                    establishment: { xs: 12, lg: 4, sm: 4 },
                }}
            />
        </div>
    );

    return (
        <Grid
            container
            sx={{
                flexDirection: "column",
            }}
        >
            <Grid item xs={12}>
                <h1>Cursos</h1>
            </Grid>
            {isAdmin && (
                <Grid item xs={12} mb={4}>
                    <Button variant="contained" mb={3} onClick={() => setOpenCourseDialog(true)}>
                        Crear curso
                    </Button>
                </Grid>
            )}
            <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} margin={"auto"}>
                {showAlertOk && (
                    <Alert severity="success">La solicitud se envió correctamente</Alert>
                )}
                {showAlertFail && (
                    <Alert severity="error">Ocurrió un error, intente nuevamente</Alert>
                )}
                {showAlert && <GenericAlert severity={"success"} text={alertText}></GenericAlert>}
            </Grid>
            <Grid item xs={12}>
                <FilterBar
                    columnVisibilityModel={columnVisibilityModel}
                    onPageChange={getCourses}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={adminTableCoursesColumns}
                    rows={courses}
                    pageSize={pageSize}
                    extraFilterComponent={extraFilterComponent}
                    component={isSuperAdmin ? componentFilterBarSuperAdmin : null}
                    newEntryConfigButton={
                        isSuperAdmin
                            ? {
                                  disabled: !Boolean(extraFilterComponent?.establishment),
                                  label: "Crear curso",
                                  onClick: () => {
                                      setOpenCourseDialog(true);
                                  },
                              }
                            : {}
                    }
                />
            </Grid>

            <GenericDialog
                title={`CREAR CURSO - ${actualEstablishment.name}`}
                open={openCourseDialog}
                setOpen={setOpenCourseDialog}
                handleSave={handleCreateCourse}
            >
                <DialogContent>
                    <DialogContentInputs
                        courseFormData={courseFormData}
                        setCourseFormData={setCourseFormData}
                    />
                </DialogContent>
            </GenericDialog>

            <GenericDialog
                title={dialogTitle}
                open={openGenericDialog}
                setOpen={setOpenGenericDialog}
                handleSave={() => handleSaveFunction(selectedCourseId)}
                saveButtonText="Si"
            >
                <DialogContent>{dialogContentText}</DialogContent>
            </GenericDialog>

            {showAlert && <GenericAlert severity={"success"} text={alertText}></GenericAlert>}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 3,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default CoursesList;
