import React, { useEffect, useState } from "react";

import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    DialogContent,
    Grid,
    TextField,
} from "@mui/material";
import { ApiService } from "common/services/ApiService";
import FilterBar from "common/components/FilterBar/FilterBar";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import GenericAlert from "common/components/GenericAlert/GenericAlert";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";

const SubjectsList = ({ isSuperAdmin = false, isAdmin = false, columnVisibilityModel }) => {
    const navigate = useNavigate();

    const { auth } = useAuth();
    const [subjects, setSubjects] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});

    // const [userRequests, setUserRequests] = useState([]);

    const [openSubjectDialog, setOpenSubjectDialog] = useState(false);
    const [openSubjectUpdateDialog, setOpenSubjectUpdateDialog] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [severity, setSeverity] = useState("");

    // const [dialogTitle, setDialogTitle] = useState("");
    // const [dialogContentText, setDialogContentText] = useState("");
    const [actualEstablishment, setActualEstablishment] = useState(
        auth.selectedRole?.name === "superadmin" ? {} : auth?.selectedEstablishment
    );

    const [extraFilterComponent, setExtraFilterComponent] = useState({
        establishment:
            auth.selectedRole?.name === "superadmin" ? "" : auth?.selectedEstablishment?.id,
    });

    const [subjectName, setSubjectName] = useState("");
    const [actualSubjectId, setActualSubjectId] = useState(null);

    const getSubjects = async (filter = "", extraFilter = {}, page = 1, pageSize = 10) => {
        setOpenLoader(true);
        const res = await ApiService.getSubjects(page, pageSize, {
            ...extraFilter,
            name: filter,
        });
        setRowCount(res.data.count);
        setPageSize(pageSize);
        setPage(page);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setSubjects(res.data);
        setOpenLoader(false);
    };

    useEffect(() => {
        getSubjects(filter, extraFilter, page, pageSize);
    }, []);

    const adminTableSubjectsColumns = [
        {
            field: "name",
            headerName: "Nombre",
            flex: 150,
        },

        {
            field: "actions",
            headerName: "Acciones",
            // flex: 1,
            width: 150,
            hideable: true,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} gap={1}>
                        <Button
                            disabled={Object.keys(actualEstablishment).length === 0}
                            variant="contained"
                            onClick={() => {
                                setSubjectName(params.row.name);
                                setOpenSubjectUpdateDialog(true);
                                setActualSubjectId(params.row.id);
                            }}
                        >
                            Editar
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const createAlert = (text, severity) => {
        setAlertText(text);
        setSeverity(severity);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 2500);
    };

    const handleCreateSubject = async (evt) => {
        try {
            const res = await ApiService.createSubject({
                establishment: actualEstablishment.id,
                name: subjectName,
            });
            if (res.status === 201) {
                getSubjects(filter, extraFilter, page, pageSize);
                createAlert("La materia se creó con éxito!", "success");
            } else {
                createAlert("Ocurrió un error, intente nuevamente!", "error");
            }
        } catch (error) {
            console.log(error);
            createAlert("Ocurrió un error, intente nuevamente!", "error");
        }
    };

    const handleUpdateSubject = async () => {
        try {
            const res = await ApiService.updateSubject(
                {
                    establishment: actualEstablishment.id,
                    name: subjectName,
                },
                actualSubjectId
            );
            if (res.status === 200) {
                getSubjects(filter, extraFilter, page, pageSize);
                createAlert("La materia se editó con éxito!", "success");
            } else {
                createAlert("Ocurrió un error, intente nuevamente!", "error");
            }
        } catch (error) {
            console.log(error);
            createAlert("Ocurrió un error, intente nuevamente!", "error");
        }
    };

    const componentFilterBarSuperAdmin = (
        <div>
            <SelectProvinceAndLocation
                handleChangeProvince={() => {}}
                handleChangeLocation={() => {}}
                handleChangeEstablishment={(establishment) => {
                    setExtraFilterComponent({
                        ...extraFilterComponent,
                        establishment: establishment?.id,
                    });
                    setActualEstablishment(establishment || {});
                    // setCourseFormData({
                    //     ...courseFormData,
                    //     establishment: establishment?.id,
                    // });
                }}
                showEstablishments={true}
                gridSizes={{
                    province: { xs: 12, lg: 4, sm: 4 },
                    location: { xs: 12, lg: 4, sm: 4 },
                    establishment: { xs: 12, lg: 4, sm: 4 },
                }}
            />
        </div>
    );

    return (
        <Grid
            container
            sx={{
                flexDirection: "column",
            }}
        >
            <Grid item xs={12}>
                <h1>Materias</h1>
            </Grid>
            {isAdmin && (
                <Grid item xs={12} mb={4}>
                    <Button variant="contained" mb={3} onClick={() => setOpenSubjectDialog(true)}>
                        Crear materia
                    </Button>
                </Grid>
            )}
            <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} margin={"auto"}>
                {showAlert && <GenericAlert severity={"success"} text={alertText}></GenericAlert>}
            </Grid>
            <Grid item xs={12}>
                <FilterBar
                    columnVisibilityModel={columnVisibilityModel}
                    onPageChange={getSubjects}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={adminTableSubjectsColumns}
                    rows={subjects}
                    pageSize={pageSize}
                    extraFilterComponent={extraFilterComponent}
                    component={isSuperAdmin ? componentFilterBarSuperAdmin : null}
                    newEntryConfigButton={
                        isSuperAdmin
                            ? {
                                  disabled: !Boolean(extraFilterComponent?.establishment),
                                  label: "Crear materia",
                                  onClick: () => {
                                      setOpenSubjectDialog(true);
                                  },
                              }
                            : {}
                    }
                />
            </Grid>
            <GenericDialog
                title={`CREAR MATERIA - ${actualEstablishment?.name}`}
                open={openSubjectDialog}
                setOpen={setOpenSubjectDialog}
                handleSave={handleCreateSubject}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        fullWidth
                        label="Nombre de materia"
                        type="text"
                        value={subjectName}
                        onChange={(evt) => setSubjectName(evt.target.value)}
                    />
                </DialogContent>
            </GenericDialog>

            <GenericDialog
                title={`EDITAR MATERIA - ${actualEstablishment?.name}`}
                open={openSubjectUpdateDialog}
                setOpen={setOpenSubjectUpdateDialog}
                handleSave={handleUpdateSubject}
            >
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        fullWidth
                        label="Nombre de materia"
                        type="text"
                        value={subjectName}
                        onChange={(evt) => setSubjectName(evt.target.value)}
                    />
                </DialogContent>
            </GenericDialog>
            {/* <GenericDialog
                title={dialogTitle}
                open={openGenericDialog}
                setOpen={setOpenGenericDialog}
                handleSave={() => handleSaveFunction(selectedCourseId)}
                saveButtonText="Si"
            >
                <DialogContent>{dialogContentText}</DialogContent>
            </GenericDialog> */}

            {showAlert && <GenericAlert severity={severity} text={alertText}></GenericAlert>}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 3,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default SubjectsList;
