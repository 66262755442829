import React, { useEffect, useState } from "react";

import DrawerComponent from "common/components/DrawerComponent/DrawerComponent";

import {
    Badge,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

import UsersList from "../../components/UsersList/UsersList";
import EstablishmentsList from "../../components/EstablishmentsList/EstablishmentsList";
import DatasetsList from "../../components/DatasestsList/DatasetsList";
import { ApiService } from "common/services/ApiService";
import { useAuth } from "context/AuthContext";
import Datasets from "modules/activity/pages/Datasets/Datasets";
import CoursesList from "modules/authentication/components/CoursesList/CorusesList";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SubjectsList from "modules/authentication/components/SubjectsList/SubjectsList";

const SuperAdmin = ({ handleDrawerToggle, openDrawer }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const { auth } = useAuth();

    const { getEstablishments, getFilterUsers } = ApiService;

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [dataToShow, setDataToShow] = useState(<h1>Menú de Super Administrador</h1>);

    const firstList = [
        {
            id: 1,
            title: "Cursos",
            component: (
                <CoursesList
                    isSuperAdmin
                    columnVisibilityModel={{
                        actions: false,
                    }}
                />
            ),
            icon: <CardMembershipIcon />,
            badgeKey: "course",
            selected: false,
        },
        {
            id: 2,
            title: "Materias",
            component: <SubjectsList isSuperAdmin />,
            icon: <AutoStoriesIcon />,
            badgeKey: "course",
            selected: false,
        },
        {
            id: 3,
            title: "Instituciones",
            component: (
                <EstablishmentsList
                    getEstablishmentsFunction={getEstablishments}
                    getUsersFunction={getFilterUsers}
                />
            ),
            icon: <ApartmentIcon></ApartmentIcon>,
            selected: false,
        },
        {
            id: 4,
            title: "Usuarios",
            component: <UsersList isSuperAdmin getUsersFunction={getFilterUsers} />,
            icon: <GroupIcon></GroupIcon>,
            selected: false,
            badgeKey: "user",
        },
        {
            id: 5,
            title: "Datasets",
            component: <Datasets />,
            icon: <InsertDriveFileIcon></InsertDriveFileIcon>,
            selected: false,
        },
    ];

    const drawer = (
        <div>
            <List>
                {firstList.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setDataToShow(item.component);
                                handleDrawerToggle();
                                setSelectedItemId(item.id);
                            }}
                            selected={selectedItemId === item.id}
                        >
                            <ListItemIcon>
                                {Boolean(item.badgeKey) &&
                                Object.keys(auth.pendingNotification || {}).length > 0 ? (
                                    <Badge
                                        variant="dot"
                                        color="secondary"
                                        invisible={
                                            !auth.pendingNotification["superadmin"][item.badgeKey]
                                        }
                                    >
                                        {item.icon}
                                    </Badge>
                                ) : (
                                    item.icon
                                )}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </div>
    );

    return (
        <DrawerComponent
            drawer={drawer}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={openDrawer}
        >
            {dataToShow}
        </DrawerComponent>
    );
};

export default SuperAdmin;
