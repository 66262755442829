import React, { useState } from "react";

import DrawerComponent from "common/components/DrawerComponent/DrawerComponent";

import ApartmentIcon from "@mui/icons-material/Apartment";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import GroupIcon from "@mui/icons-material/Group";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
    Badge,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
// import UsersList from "modules/authentication/components/UsersList/UsersList";
// import EstablishmentsList from "modules/authentication/components/EstablishmentsList/EstablishmentsList";
// import DatasetsList from "modules/authentication/components/DatasestsList/DatasetsList";
import { ApiService } from "common/services/ApiService";
import CoursesList from "modules/authentication/components/CoursesList/CorusesList";
import ActivitiesList from "modules/authentication/components/ActivitiesList/ActivitiesList";
import Datasets from "modules/activity/pages/Datasets/Datasets";

const Teacher = ({ handleDrawerToggle, openDrawer }) => {
    const { getEstablishments, getFilterUsers } = ApiService;

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [dataToShow, setDataToShow] = useState(<h1>Menú de Profesor</h1>);

    const firstList = [
        {
            id: 1,
            title: "Cursos",
            component: (
                <CoursesList
                    columnVisibilityModel={{
                        adminActions: false,
                    }}
                />
            ),
            icon: <CardMembershipIcon></CardMembershipIcon>,
            badge: true,
            selected: false,
        },
        {
            id: 2,
            title: "Actividades",
            component: <ActivitiesList />,
            icon: <InsertDriveFileIcon></InsertDriveFileIcon>,
            selected: false,
        },
        {
            id: 3,
            title: "Datasets",
            component: <Datasets />,
            icon: <InsertDriveFileIcon></InsertDriveFileIcon>,
            selected: false,
        },
    ];

    const drawer = (
        <div>
            <List>
                {firstList.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setDataToShow(item.component);
                                handleDrawerToggle();
                                setSelectedItemId(item.id);
                            }}
                            selected={selectedItemId === item.id}
                        >
                            <ListItemIcon>
                                {item.badge ? <Badge variant="dot">{item.icon}</Badge> : item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </div>
    );

    return (
        <DrawerComponent
            drawer={drawer}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={openDrawer}
        >
            {dataToShow}
        </DrawerComponent>
    );
};

export default Teacher;
