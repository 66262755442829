import { CircularProgress, Grid, MenuItem, TextField } from "@mui/material";
import AsyncSelect from "common/components/AsyncSelect/AsyncSelect";
import { ApiService } from "common/services/ApiService";
import React, { useEffect, useState } from "react";

const SelectProvinceAndLocation = ({
    handleChangeProvince = () => {},
    handleChangeLocation = () => {},
    handleChangeEstablishment = () => {},
    showEstablishments = false,
    gridSizes = {
        province: { xs: 6, lg: 4, sm: 4 },
        location: { xs: 6, lg: 4, sm: 4 },
        establishment: { xs: 6, lg: 4, sm: 4 },
    },
    useToken = true,
}) => {
    const [provinces, setProvinces] = useState([]);
    const [locations, setLocations] = useState([]);
    const [establishments, setEstablishments] = useState([]);
    const [establishment, setEstablishment] = useState([]);
    const [locationId, setLocationId] = useState(null);
    const [provinceId, setProvinceId] = useState(null);

    useEffect(() => {
        async function getProvinces() {
            const res = await ApiService.getProvinces();
            setProvinces(res.data);
        }

        getProvinces();
    }, []);

    const getLocations = async (provinceId, search = "") => {
        const res = await ApiService.getLocations(null, null, {
            province: provinceId,
            name: search,
        });
        setLocations(res.data.results);
        return res.data.results;
    };

    const getEstablishments = async (locationId = "", search = "") => {
        const res = await ApiService.getEstablishments(
            null,
            null,
            {
                location: locationId,
                name: search,
            },
            useToken
        );
        setEstablishments(res.data.results);
        return res.data.results;
    };

    const searchLocations = (provinceId, search = "") => {
        if (!provinces.length) return [];
        getLocations(provinceId, search);
    };

    const searchEstablishments = (search) => {
        getEstablishments(locationId, search);
    };

    return (
        <Grid container spacing={2}>
            <Grid item {...gridSizes.province}>
                <TextField
                    select
                    label="Seleccione una provincia"
                    fullWidth
                    defaultValue={""}
                    onChange={(evt) => {
                        handleChangeProvince(evt);
                        searchLocations(evt.target.value);
                        setProvinceId(evt.target.value);
                    }}
                >
                    {provinces.map((province) => (
                        <MenuItem key={province.id} value={province.id}>
                            {province.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item {...gridSizes.location}>
                <AsyncSelect
                    disabled={!provinces.length}
                    options={locations}
                    onChangeSearch={async (search) => {
                        searchLocations(provinceId, search);
                    }}
                    onChangeSelect={(value) => {
                        handleChangeLocation(value);
                        setLocationId(value?.id);
                        getEstablishments(value?.id);
                    }}
                    label="Seleccione una localidad"
                />
            </Grid>
            {showEstablishments && (
                <Grid item {...gridSizes.establishment}>
                    <AsyncSelect
                        disabled={!locationId}
                        options={establishments}
                        onChangeSearch={(search) => searchEstablishments(search)}
                        onChangeSelect={(value) => {
                            handleChangeEstablishment(value);
                            setEstablishment(value);
                        }}
                        label="Seleccione una institución"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default SelectProvinceAndLocation;
