import {
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    DialogContent,
    Grid,
} from "@mui/material";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import { useAuth } from "context/AuthContext";
import React, { useState } from "react";
import { ApiService } from "common/services/ApiService";
import { useEffect } from "react";
import { snakeCaseObject } from "helpers/TextHelpers";
import FilterBar from "common/components/FilterBar/FilterBar";
import { VISIBILITY } from "utils/constants";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import DialogContentCreate from "./DialogContentCreate";
import DialogContentClone from "./DialogContentClone";
import DialogContentAssign from "./DialogContentAssign";
import GenericAlert from "common/components/GenericAlert/GenericAlert";

const visibilityOptions = () => [
    {
        value: "",
        label: "Todo",
        show: true,
    },
    {
        value: VISIBILITY.public,
        label: "Establecimiento",
        show: true,
    },
    {
        value: VISIBILITY.private,
        label: "Privado",
        show: true,
    },
    {
        value: VISIBILITY.community,
        label: "Comunidad",
        show: true,
    },
];

const extraFilterList = [
    {
        type: "radio",
        key: "visibility",
        items: visibilityOptions(),
    },
];

const ActivitiesList = ({ isAdmin = false, columnVisibilityModel = null }) => {
    const { auth } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [severity, setSeverity] = useState("");

    const [createContent, setCreateContent] = useState(false);
    const [cloneContent, setCloneContent] = useState(false);
    const [assignContent, setAssignContent] = useState(false);

    const [selectedActivity, setSelectedActivity] = useState("");
    const [selectedCourse, setSelectedCourse] = useState("");

    const [modalTitle, setModalTitle] = useState("");

    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});
    const [page, setPage] = useState(1);
    const [extraFilterComponent, setExtraFilterComponent] = useState({});

    const [activities, setActivities] = useState([]);

    const [activityFormData, setActivityFormData] = useState({
        course: "",
        title: "",
        statementTitle: "",
        statement: "",
        objective: "",
        description: "",
        dataset: "",
        graphsData: {},
        startDate: "",
        endDate: "",
        socialGrahpsEnabled: false,
        qualifyingActivity: false,
        establishment: auth.selectedEstablishment.id,
        visibility: VISIBILITY.public,
    });

    const [activityCloneFormData, setActivityCloneFormData] = useState({
        statementTitle: "",
        statement: "",
        establishment: auth.selectedEstablishment.id,
        visibility: VISIBILITY.public,
    });

    const [assignCourseFormData, setAssignCourseFormData] = useState({
        course: null,
        students: [],
    });

    const componentEstablishmentFilterBar = (
        <SelectProvinceAndLocation
            handleChangeProvince={() => {}}
            handleChangeLocation={() => {}}
            handleChangeEstablishment={(establishment) => {
                setExtraFilterComponent({
                    ...extraFilterComponent,
                    establishment: establishment?.id,
                });
            }}
            showEstablishments={true}
            gridSizes={{
                province: { xs: 12, lg: 4, sm: 4 },
                location: { xs: 12, lg: 4, sm: 4 },
                establishment: { xs: 12, lg: 4, sm: 4 },
            }}
        />
    );

    const extraFilterCallback = (filters) => {
        const aux = { ...filters };
        delete aux.visibility;
        setExtraFilterComponent(aux);
    };

    const getActivities = async (
        filter = "",
        extraFilter = {},
        page = 1,
        pageSize = 10
    ) => {
        setOpenLoader(true);
        const params = {
            ...extraFilter,
            search: filter,
        };

        const res = await ApiService.getActivities(page, pageSize, params);

        setRowCount(res.data.count);
        setPageSize(pageSize);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setPage(page);

        setActivities(
            res.data.results.map((activity) => ({
                id: activity.id,
                title: activity.title,
                description: activity.description,
                establishment: activity.establishment,
                visibility: activity.visibility,
                graphs: activity.graphs,
            }))
        );
        setOpenLoader(false);
    };

    const showAlertFunction = (severity) => {
        setShowAlert(true);
        setSeverity(severity);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };

    const handleCreateActivity = async () => {
        const snakeCaseData = snakeCaseObject(activityFormData);

        if (
            activityFormData.title === "" ||
            activityFormData.statement_title === "" ||
            activityFormData.statement === "" ||
            activityFormData.objective === "" ||
            activityFormData.description === "" ||
            Object.values(activityFormData.graphsData).every(
                (value) => value === false
            )
        ) {
            setAlertText("Debe completar todos los campos!");
            showAlertFunction("error");
            return;
        }

        try {
            setOpenDialog(false);
            setOpenLoader(true);
            await ApiService.createActivity(snakeCaseData);
            setOpenLoader(false);
            getActivities(filter, extraFilter, page, pageSize);
            setAlertText("La actividad fue creada con éxito!");
            showAlertFunction("success");
        } catch (error) {
            console.log(error);
            setOpenLoader(false);
        }
    };

    const handleAssignCourse = async (evt) => {
        try {
            const snakeCaseData = snakeCaseObject(assignCourseFormData);
            snakeCaseData.activity = selectedActivity.id;
            const res = await ApiService.createSubjectCourseActivity(
                snakeCaseData
            );
            setAlertText("La actividad fue asignada al curso con éxito!");
            showAlertFunction("success");
            setOpenDialog(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloneActivity = async (evt) => {
        try {
            const snakeCaseData = snakeCaseObject(activityCloneFormData);
            if (
                snakeCaseData.title === "" ||
                snakeCaseData.statement_title === "" ||
                snakeCaseData.statement === "" ||
                snakeCaseData.objective === "" ||
                snakeCaseData.description === "" ||
                snakeCaseData.description === "" ||
                Object.keys(snakeCaseData.graphs_data).length === 0
            ) {
                setAlertText("Debe completar todos los campos!");
                showAlertFunction("error");
                return;
            }
            const idActivity = selectedActivity.id;
            const res = await ApiService.cloneActivity(
                snakeCaseData,
                idActivity
            );
            if (res.status === 200) {
                setAlertText("La actividad fue clonada con éxito!");
                showAlertFunction("success");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeModalContent = (setter, title) => {
        setCreateContent(false);
        setCloneContent(false);
        setAssignContent(false);
        setter(true);
        setModalTitle(title);
    };

    const activitiesColumns = [
        { field: "title", headerName: "Titúlo", width: 150 },
        { field: "description", headerName: "Descripción", width: 150 },
        { field: "establishment", headerName: "Establecimiento", width: 450 },
        {
            field: "actions",
            headerName: "Acciones",
            width: 450,
            hideable: true,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            gap: "3px",
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenDialog(true);
                                setSelectedActivity(params.row);
                                handleChangeModalContent(setCloneContent, "CLONAR");
                            }}
                        >
                            Clonar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOpenDialog(true);
                                setSelectedActivity(params.row);
                                handleChangeModalContent(setAssignContent, "ASIGNAR");
                            }}
                        >
                            Asignar a curso
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <Grid container spacing={2} sx={{ flexDirection: "column" }}>
            <Grid item xs={12}>
                <h1>Actividades</h1>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    mb={3}
                    onClick={() => {
                        setOpenDialog(true);
                        handleChangeModalContent(setCreateContent, "CREAR");
                    }}
                >
                    Crear actividad
                </Button>
            </Grid>
            <Grid item xs={12}>
                <FilterBar
                    columnVisibilityModel={columnVisibilityModel}
                    onPageChange={getActivities}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={activitiesColumns}
                    rows={activities}
                    pageSize={pageSize}
                    enableExtraFilter
                    extraFilterList={extraFilterList}
                    extraFilterComponent={extraFilterComponent}
                    extraFilterCallback={extraFilterCallback}
                    component={
                        extraFilter.visibility === VISIBILITY.community
                            ? componentEstablishmentFilterBar
                            : undefined
                    }
                />
            </Grid>
            <GenericDialog
                title={`${modalTitle} ACTIVIDAD - ${auth.selectedEstablishment.name}`}
                open={openDialog}
                setOpen={setOpenDialog}
                handleSave={
                    assignContent
                        ? handleAssignCourse
                        : createContent
                        ? handleCreateActivity
                        : handleCloneActivity
                }
            >
                <DialogContent>
                    {createContent && (
                        <DialogContentCreate
                            activityFormData={activityFormData}
                            setActivityFormData={setActivityFormData}
                        />
                    )}
                    {cloneContent && (
                        <DialogContentClone
                            activityCloneFormData={activityCloneFormData}
                            setActivityCloneFormData={setActivityCloneFormData}
                            selectedActivity={selectedActivity}
                        />
                    )}
                    {assignContent && (
                        <DialogContentAssign
                            selectedCourse={selectedCourse}
                            setSelectedCourse={setSelectedCourse}
                            setActivityFormData={setActivityFormData}
                            assignCourseFormData={assignCourseFormData}
                            setAssignCourseFormData={setAssignCourseFormData}
                        />
                    )}
                </DialogContent>
            </GenericDialog>

            {showAlert && (
                <Grid
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "40%",
                        zIndex: 99999,
                    }}
                >
                    <GenericAlert
                        severity={severity}
                        text={alertText}
                    ></GenericAlert>
                </Grid>
            )}

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 3,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default ActivitiesList;
