import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Button, Menu, MenuItem, Badge } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import GenericMenuItem from "common/components/GenericMenuItem/GenericMenuItem";
import { useNavigate } from "react-router-dom";
import { datasetSubPages, grafosSocialesSubPages } from "../utils/constants";
import { useAuth } from "context/AuthContext";

const LoggedUserMenuMobile = ({
    anchorElNav,
    handleCloseNavMenu,
    handleOpenNavMenu,
    handleOpenMenu,
    handleCloseMenu,
    anchorElements,
}) => {
    const { auth } = useAuth();
    const [pendingNotification, setPendingNotification] = useState(auth.pendingNotification);
    const [selectedRole, setSelectedRole] = useState(auth.selectedRole);

    useEffect(() => {
        setPendingNotification(auth.pendingNotification);
        setSelectedRole(auth.selectedRole);
    }, [auth]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: { xs: "block", sm: "none", md: "none" },
                flex: 0.2,
            }}
        >
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <Badge
                    variant="dot"
                    color="secondary"
                    invisible={
                        selectedRole === null ||
                        Object.keys(pendingNotification || {}).length === 0 ||
                        (Object.keys(pendingNotification || {}).length > 0 &&
                            selectedRole &&
                            selectedRole.name in pendingNotification &&
                            !Object.values(pendingNotification[selectedRole.name]).some(
                                (pending) => pending
                            )) ||
                        (Object.keys(pendingNotification || {}).length > 0 &&
                            selectedRole &&
                            !(selectedRole.name in pendingNotification))
                    }
                >
                    <MenuIcon />
                </Badge>
            </IconButton>
        </Box>
    );
};

LoggedUserMenuMobile.propTypes = {
    anchorElNav: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    handleCloseNavMenu: PropTypes.func.isRequired,
    handleOpenNavMenu: PropTypes.func.isRequired,
    // handleOpenDatasetsMenu: PropTypes.func.isRequired,
    // handleOpenGrafosMenu: PropTypes.func.isRequired,
    // handleCloseDatasets: PropTypes.func.isRequired,
    // handleCloseGrafos: PropTypes.func.isRequired,
    // anchorElDatasets: PropTypes.oneOfType([
    //     PropTypes.func,
    //     PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    // ]),
    // anchorElGrafos: PropTypes.oneOfType([
    //     PropTypes.func,
    //     PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    // ]),
};

export default LoggedUserMenuMobile;
