import {
    Backdrop,
    Button,
    CircularProgress,
    DialogContent,
    Grid,
} from "@mui/material";
import { React, useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { useAuth } from "context/AuthContext";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import FilterBar from "common/components/FilterBar/FilterBar";
import { ApiService } from "common/services/ApiService";
import { VISIBILITY } from "utils/constants";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";
import FileDropzone from "common/components/FileDropzone/FileDropzone";
import GenericAlert from "common/components/GenericAlert/GenericAlert";

const Datasets = (props) => {
    const { title } = props;
    const navigate = useNavigate();
    const { auth } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [contentDialog, setContentDialog] = useState(false);
    const [openLoader, setOpenLoader] = useState(false);
    const [datasets, setDatasets] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filter, setFilter] = useState("");
    const [extraFilter, setExtraFilter] = useState({});
    const [page, setPage] = useState(1);
    const [extraFilterComponent, setExtraFilterComponent] = useState({});

    const [file, setFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [inputsFromCSV, setInputsFromCSV] = useState([]);
    const [inputsFromUser, setInputsFromUser] = useState({});
    const [hasHeaders, setHasHeaders] = useState(false);

    const [alertSeverity, setAlertSeverity] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const columns = [
        { field: "datasetName", headerName: "Nombre del dataset", flex: 1 },
        { field: "uploadBy", headerName: "Subido por", flex: 1 },
        {
            field: "actions",
            headerName: "Acciones",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Grid container gap={1} flexDirection={"row"}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => navigate("/graphs")}
                            >
                                Ver
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={() => navigate("/grapher")}
                            >
                                Graficar
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
    ];

    const datasetsColumns = [
        { field: "name", headerName: "Titúlo", flex: 1 },
        // {
        //     field: "actions",
        //     headerName: "Acciones",
        //     flex: 1,
        //     hideable: true,
        //     renderCell: (params) => {
        //         return (
        //             <div
        //                 style={{
        //                     display: "flex",
        //                     gap: "3px",
        //                 }}
        //             >
        //                 <Button
        //                     variant="contained"
        //                     onClick={() => {
        //                         setOpenDialog(true);
        //                         setSelectedActivity(params.row);
        //                         handleChangeModalContent(
        //                             setCloneContent,
        //                             "CLONAR"
        //                         );
        //                     }}
        //                 >
        //                     Clonar
        //                 </Button>
        //             </div>
        //         );
        //     },
        // },
    ];

    const visibilityOptions = () => [
        {
            value: "",
            label: "Todo",
            show: true,
        },
        {
            value: VISIBILITY.public,
            label: "Establecimiento",
            show: true,
        },
        {
            value: VISIBILITY.private,
            label: "Privado",
            show: true,
        },
        {
            value: VISIBILITY.community,
            label: "Comunidad",
            show: true,
        },
    ];

    const extraFilterList = [
        {
            type: "radio",
            key: "visibility",
            items: visibilityOptions(),
        },
    ];

    const componentEstablishmentFilterBar = (
        <SelectProvinceAndLocation
            handleChangeProvince={() => {}}
            handleChangeLocation={() => {}}
            handleChangeEstablishment={(establishment) => {
                setExtraFilterComponent({
                    ...extraFilterComponent,
                    establishment: establishment?.id,
                });
            }}
            showEstablishments={true}
            gridSizes={{
                province: { xs: 12, lg: 4, sm: 4 },
                location: { xs: 12, lg: 4, sm: 4 },
                establishment: { xs: 12, lg: 4, sm: 4 },
            }}
        />
    );

    // const extraFilterCallback = (filters) => {
    //     const aux = { ...filters };
    //     delete aux.visibility;
    //     setExtraFilterComponent(aux);
    // };

    const getDatasets = async (
        filter = "",
        extraFilter = {},
        page = 1,
        pageSize = 10
    ) => {
        setOpenLoader(true);
        const params = {
            ...extraFilter,
            search: filter,
        };

        const res = await ApiService.getStaticDataSources(
            page,
            pageSize,
            params
        );

        setRowCount(res.data.count);
        setPageSize(pageSize);
        setFilter(filter);
        setExtraFilter(extraFilter);
        setPage(page);

        setDatasets(res.data.results);
        setOpenLoader(false);
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) =>
            Papa.parse(file, {
                worker: true,
                complete: (results) => {
                    resolve(results.data.filter((r) => !r[0].includes("#"))[0]);
                },
                error: (err) => reject(err),
            })
        );
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        setOpen(true);
        setFile(acceptedFiles[0]);
        if (acceptedFiles[0].type.includes("csv"))
            await readFile(acceptedFiles[0])
                .then((data) => setInputsFromCSV(data))
                .catch((error) => console.log(error));

        setOpen(false);
    }, []);

    const handleShowAlert = (variant, msg) => {
        setAlertSeverity(variant);
        setAlertMsg(msg);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertSeverity("");
            setAlertMsg("msg");
        }, 5000);
    };

    const handleClickButton = async () => {
        setOpen(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("has_headers", hasHeaders);

        if (file.type.includes("csv")) {
            formData.append("headers_file", JSON.stringify(inputsFromCSV));
            formData.append("headers_user", JSON.stringify([]));
            if (!hasHeaders) {
                formData.set(
                    "headers_user",
                    JSON.stringify(Object.values(inputsFromUser))
                );
            }
        }

        const res = await ApiService.uploadFile(formData);

        if (res.status === 200) {
            setOpenDialog(false);
            handleShowAlert("success", "Dataset cargado con éxito!");
        } else {
            handleShowAlert("error", "Ocurrió un error, intente nuevamente!");
            setOpen(false);
        }
    };

    return (
        <Grid container spacing={2} sx={{ flexDirection: "column" }}>
            <Grid item xs={12}>
                <h1>Datasets</h1>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    mb={3}
                    onClick={() => {
                        setOpenDialog(true);
                        // handleChangeModalContent(setCreateContent, "CREAR");
                    }}
                >
                    Subir Dataset
                </Button>
            </Grid>
            <Grid item xs={12}>
                <FilterBar
                    onPageChange={getDatasets}
                    openLoader={openLoader}
                    rowCount={rowCount}
                    columns={datasetsColumns}
                    rows={datasets}
                    pageSize={pageSize}
                    enableExtraFilter
                    extraFilterList={extraFilterList}
                    extraFilterComponent={extraFilterComponent}
                    component={
                        extraFilter.visibility === VISIBILITY.community
                            ? componentEstablishmentFilterBar
                            : undefined
                    }
                />
            </Grid>
            <GenericDialog
                title="Agregar Dataset"
                open={openDialog}
                setOpen={setOpenDialog}
                handleSave={() => handleClickButton()}
                onClose={() => {
                    setFile(null);
                    setInputsFromCSV([]);
                }}
            >
                <DialogContent>
                    <FileDropzone
                        file={file}
                        inputsFromCSV={inputsFromCSV}
                        onDrop={onDrop}
                        open={open}
                        setInputsFromUser={setInputsFromUser}
                        inputsFromUser={inputsFromUser}
                        hasHeaderCallback={(value) => setHasHeaders(value)}
                    />
                </DialogContent>
            </GenericDialog>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 3,
                }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {showAlert && (
                <GenericAlert
                    severity={alertSeverity}
                    text={alertMsg}
                    style={{
                        position: "fixed",
                        zIndex: "9999999999999999",
                        top: "50%",
                        left: "30%",
                    }}
                />
            )}
        </Grid>
    );
};

export default Datasets;
