import React, { useEffect, useState } from "react";
import { ApiService } from "common/services/ApiService";

import { Button, Tooltip } from "@mui/material";
import GenericDialog from "common/components/GenericDialog/GenericDialog";
import DialogEstablishmentContent from "../../pages/SuperAdmin/components/DialogEstablishmentContent";
import FilterBar from "common/components/FilterBar/FilterBar";
import SelectProvinceAndLocation from "common/components/SelectProvinceAndLocation/SelectProvinceAndLocation";

const EstablishmentsList = ({
    getEstablishmentsFunction,
    getUsersFunction,
}) => {
    const [openLoader, setOpenLoader] = useState(false);
    const [establishments, setEstablishments] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedEstablishment, setSelectedEstablishment] = useState(null);
    const [managersForEstablishment, setManagersForEstablishment] = useState(
        []
    );
    const [responsibleForEstablishment, setResponsibleForEstablishment] =
        useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [extraFilterComponent, setExtraFilterComponent] = useState({
        location: "",
    });

    const [openDialog, setOpenDialog] = useState(false);

    const getData = async (
        filter = "",
        extraFilter = {},
        page = 1,
        pageSize = 10
    ) => {
        setOpenLoader(true);
        const res = await getEstablishmentsFunction(page, pageSize, {
            name: filter,
            ...extraFilter,
        });

        setRowCount(res.data.count);
        setEstablishments(res.data.results);
        setPageSize(pageSize);
        setPage(page);
        setOpenLoader(false);
    };

    useEffect(() => {
        const getUsers = async () => {
            const us = await getUsersFunction(null, null, {
                no_page: 1,
                // role_name: "admin",
            });
            setUsers(us.data);
            console.log(us.data);
        };
        getUsers();
    }, []);

    const openModal = (establishment) => {
        setSelectedEstablishment(establishment);

        const selectedResponsible = users.find(
            (user) => user.id === establishment.responsible
        );

        selectedResponsible !== undefined
            ? setResponsibleForEstablishment(selectedResponsible)
            : setResponsibleForEstablishment(null);

        const selectedManagers = establishment.managers.map((manager) =>
            users.find((user) => user.id === manager)
        );
        console.log(selectedManagers);

        selectedManagers.length > 0
            ? setManagersForEstablishment(selectedManagers)
            : setManagersForEstablishment([]);

        setOpenDialog(true);
    };
    const superAdminTableInstitutionsColumns = [
        {
            field: "name",
            headerName: "Institución",
            width: 300,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.name}>
                        <p
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {params.row.name}
                        </p>
                    </Tooltip>
                );
            },
        },
        {
            field: "province",
            headerName: "Provincia",
            width: 300,
            disableColumnMenu: true,
        },
        {
            field: "location",
            headerName: "Localidad",
            width: 300,
            disableColumnMenu: true,
        },

        {
            field: "actions",
            headerName: "Acciones",
            width: 100,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModal(params.row);
                            }}
                        >
                            Ver
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleSetManagersToEstablishment = (admins) => {
        setManagersForEstablishment(admins);
    };

    const handleSetResponsibleToEstablishment = (responsible) => {
        setResponsibleForEstablishment(responsible);
    };

    // const saveAdmins = async () => {
    //     const managersIds = managersForEstablishment.map((manager) => {
    //         return manager.id;
    //     });

    //     const data = {
    //         responsible: responsibleForEstablishment.id,
    //         managers: managersIds,
    //     };

    //     const res = await ApiService.setEstablishmentManagers(
    //         selectedEstablishment.id,
    //         data
    //     );

    //     if (res.status === 200) {
    //         setOpenDialog(false);
    //         setOpenLoader(true);
    //         const res = await ApiService.getEstablishments(page, pageSize, {});
    //         setEstablishments(res.data.results);
    //         setOpenLoader(false);
    //     }
    // };

    return (
        <div>
            <h1>Instituciones</h1>
            <FilterBar
                onPageChange={getData}
                openLoader={openLoader}
                rowCount={rowCount}
                columns={superAdminTableInstitutionsColumns}
                rows={establishments}
                pageSize={pageSize}
                extraFilterComponent={extraFilterComponent}
                component={
                    <SelectProvinceAndLocation
                        handleChangeProvince={() => {}}
                        handleChangeLocation={(e) =>
                            setExtraFilterComponent({
                                location: e.target.value,
                            })
                        }
                        gridSizes={{
                            province: { xs: 12, lg: 6, sm: 6 },
                            location: { xs: 12, lg: 6, sm: 6 },
                        }}
                    />
                }
            />
            {openDialog !== false && (
                <GenericDialog
                    open={openDialog}
                    setOpen={setOpenDialog}
                    title={selectedEstablishment.name}
                    // handleSave={saveAdmins}
                    saveButton={false}
                >
                    <DialogEstablishmentContent
                        selectedEstablishment={selectedEstablishment}
                        users={users}
                        handleSetManagersToEstablishment={handleSetManagersToEstablishment}
                        handleSetResponsibleToEstablishment={handleSetResponsibleToEstablishment}
                        selectedResponsible={responsibleForEstablishment}
                        selectedManagers={managersForEstablishment}
                    ></DialogEstablishmentContent>
                </GenericDialog>
            )}
        </div>
    );
};

export default EstablishmentsList;
