import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./styles.css";
import { Badge, useMediaQuery } from "@mui/material";
import { useAuth } from "context/AuthContext";
import { useEffect } from "react";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;

function DrawerComponent(props) {
    const { window, drawer, children, handleDrawerToggle, mobileOpen } = props;
    const { auth } = useAuth();
    const [pendingNotification, setPendingNotification] = useState(
        auth.pendingNotification
    );
    const [selectedRole, setSelectedRole] = useState(auth.selectedRole);

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const isMobile = useMediaQuery("(max-width: 600px)");

    useEffect(() => {
        setPendingNotification(auth.pendingNotification);
        setSelectedRole(auth.selectedRole);
    }, [auth]);

    return (
        <Box sx={{ display: "flex" }}>
            {/* {isMobile && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        ml: 2,
                        mt: 10,
                        zIndex: "10",
                        display: { sm: "none" },
                        position: "fixed",
                    }}
                >
                    <Badge
                        variant="dot"
                        color="secondary"
                        invisible={
                            selectedRole === null ||
                            Object.keys(pendingNotification || {}).length ===
                                0 ||
                            (Object.keys(pendingNotification || {}).length >
                                0 &&
                                selectedRole &&
                                selectedRole.name in pendingNotification &&
                                !Object.values(
                                    pendingNotification[selectedRole.name]
                                ).some((pending) => pending)) ||
                            (Object.keys(pendingNotification || {}).length >
                                0 &&
                                selectedRole &&
                                !(selectedRole.name in pendingNotification))
                        }
                    >
                        <MenuIcon />
                    </Badge>
                </IconButton>
            )} */}
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {isMobile ? (
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", sm: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                            },
                            "& ul": {
                                paddingTop: "25px",
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                ) : (
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                            },
                            "& ul": {
                                marginTop: "10px",
                                paddingTop: "15px",
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                )}
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pr: 3,
                    pl: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

DrawerComponent.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerComponent;
